<template>
  <v-card flat style="background-color: #F2F2F2" :style="$vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'">
    <v-tabs v-model="tab" grow>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <div class="mb-4">
      <v-data-table
          :headers="headers"
          :items="[data]"
          item-key="id"
          class="mt-3"
          hide-default-footer
          disable-sort
          mobile-breakpoint="0"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
          style="backgroud-color: transparent;">
        <template v-slot:header.name="{header}">
          <span style="font-size:13px;color:#484848;">{{header.text}}</span>
        </template>
        <template v-slot:header.price="{header}">
          <span style="font-size:13px;color:#484848;">{{header.text}}</span>
        </template>
        <template v-slot:header.count="{header}">
          <span style="font-size:13px;color:#484848;">{{header.text}}</span>
        </template>
        <template v-slot:header.done="{header}">
          <span style="font-size:13px;color:#484848;">{{header.text}}</span>
        </template>
        <template v-slot:header.wait="{header}">
          <span style="font-size:13px;color:#484848;">{{header.text}}</span>
        </template>
        <template v-slot:item.name="{item}">
          <v-row class="body-1" style="color:#484848">
            {{item.name}}
          </v-row>
          <v-row class="hidden-md-and-up">
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                확정비용
              </v-row>
              <v-row class="body-1" style="color:#484848">
                ₩{{item.price | numFormat}}
              </v-row>
            </v-col>
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                진행 인원
              </v-row>
              <v-row class="body-1" style="color:#484848">
                {{item.count}}명
              </v-row>
            </v-col>
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                후기 등록 완료
              </v-row>
              <v-row class="body-1" style="color:#484848">
                {{item.done}}명
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.price="{item}">
          <v-row class="body-1" style="color:#484848">
            ₩{{item.price | numFormat}}
          </v-row>
        </template>
        <template v-slot:item.count="{item}">
          <v-row class="body-1" style="color:#484848">
            {{item.count}}명
          </v-row>
        </template>
        <template v-slot:item.done="{item}">
          <v-row class="body-1" style="color:#484848">
            {{item.done}}명
          </v-row>
        </template>
        <template v-slot:item.wait="{item}">
          <v-row class="body-1" style="color:#484848">
            {{item.wait}}명
          </v-row>
        </template>
      </v-data-table>
    </div>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <component :is="item.content" :campaign="campaigns[params.index]" :propositions="propositions" :ref="item.name" @hasChanged="value => hasChanged=value" @update:proposition="updateProposition()"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import campaignMixin from '@/components/shared/campaignMixin'

  export default {
    name: "CampaignDetail",
    mixins: [userMixin, campaignMixin],
    data() {
      return {
        tab: null,
        items: [
          {tab: '이용 관리', content: () => import('@/components/campaign/detail/Manage'), name: 'manage'},
          {tab: '마케팅 보고서', content: () => import('@/components/campaign/detail/Report'), name: 'report'},
          {tab: '캠페인 수정', content: () => import('@/components/campaign/detail/Modify'), name: 'modify'},
        ],
        params: {
          index: parseInt(this.$route.params.index),
          campaign: this.$route.params.campaign
        },
        propositions: [],
        headers_desktop: [
          { text: '캠페인 명', value: 'name' },
          { text: '확정 비용', value: 'price' },
          { text: '진행인원', value: 'count' },
          { text: '후기 등록 완료', value: 'done' },
          { text: '후기 등록 대기', value: 'wait' }
        ],
        headers_mobile: [
          { text: '캠페인 명', value: 'name' }
        ],
        data: {},
        hasChanged: false
      }
    },
    computed: {
      headers() {
        return this.$vuetify.breakpoint.mdAndUp ? this.headers_desktop : this.headers_mobile;
      }
    },
    watch: {
      tab: {
        handler () {
          this.$router.replace({ name: "CampaignDetail", params: {index: this.params.index}, query: {tab: this.tab}})
        },
        deep: true
      }
    },
    methods: {
      async updateProposition() {
        let propositions = await this.axios.get('/campaign/proposition?campaignId=' + this.campaigns[this.params.index].id/*this.params.campaign.id*/);
        this.propositions = propositions.data.propositions;
      },
    },
    async created() {
      let propositions = await this.axios.get('/campaign/proposition?campaignId=' + this.campaigns[this.params.index].id/*this.params.campaign.id*/);
      this.propositions = propositions.data.propositions;

      this.data = {
        name: this.campaigns[this.params.index].name,
        price: 0,
        count: 0,
        wait: 0,
        done: 0
      }
      await Promise.all(this.propositions.map((proposition) => {
        if(proposition.status === 2 || (proposition.status >= 10 && proposition.status < 50)) {
          this.data.price += proposition.point;
          this.data.count++;
          if (proposition.status < 32) {
            this.data.wait++;
          }
          if (proposition.status >= 32) {
            this.data.done++;
          }
        }
      }));
    },
    async mounted() {
      this.tab = parseInt(this.$route.query.tab || '0');
      await this.$store.dispatch('getCampaignInfo', {id: this.user.id});
    },
    beforeRouteLeave(to, from, next) {
      if (this.hasChanged) {
        let confirmed = window.confirm("저장되지 않은 변경사항이 있습니다.\n페이지에서 나가시겠습니까?");
        if (confirmed) {
          next();
        }
      }
      else {
        next();
      }
    }
  };
</script>
